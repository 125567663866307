body {
  color: #623410;
  background: #bf7f4f url(../images/imgd465series/bg_body_8.png);
}

header {
  background-color: #c46820;
}

#main-conteneur{
  background-color: white;
}

.container_12 {
  background: #c46820 url(../images/imgd465series/bg_sidebar.png) repeat-y;
}

nav#nav-principal > ul {
  text-shadow: 0 1px 0 #000;
  color: #fff;
}

.button, .content_p .sous_navigation_link_p, .content_p .menu-lateral li .sous_navigation_link_p, .alert_text .addmsglo, #contact input[type=submit], #form input[type=submit], .produits .addbasket, .tout-produits, #paiementchoix .button {
  color: #623410;
  background: #feeee2;
}

.liste-produits .tout-produits a {
  color: #623410;
}

.button:hover, .content_p .sous_navigation_link_p:hover, .content_p .menu-lateral li .sous_navigation_link_p:hover, .alert_text .addmsglo:hover, #contact input[type=submit], #form input[type=submit], .produits .addbasket:hover, .tout-produits:hover, #paiementchoix .button:hover {
  background: #fbcda9;
}

.liste-produits {
  border-top: 1px solid #fbcda9;
  border-bottom: 1px solid #fbcda9;
}

.produits {
  text-shadow: 0 1px 0 #623410;
  background: #c46820;
}

.produitsHover{
  background-color: #c46820;
}

.produits .prixprod {
  color: #fff76b;
}

.produits .oldprix {
  color: #f79b53;
}

.produits .remise, #fiche-produit .wrap-images .grande-image .remise-produit {
  text-shadow: 0 1px 0 #ffff53;
  color: #000;
  background: #ffc100;
}

.form-contact .bt-envoyer {
  border-top: 1px solid #fe995d;
  background: #fe1416;
}

.form-contact .bt-envoyer:hover {
  border-top-color: #f9070e;
  background: #f9070e;
}

.form-contact .bt-envoyer:active {
  border-top-color: #683711;
  background: #683711;
}

.livre-or-messages {
  background: #fde4d0;
}

.livre-or-messages .footer-message {
  border-top: 1px dotted #f79b53;
}

.addmsglo {
  border-bottom: 1px #666 solid;
}

::-moz-selection, ::selection, .content_p .sous_navigation_link_p:active, .content_p .menu-lateral li .sous_navigation_link_p:active {
  color: #fff;
  background: #fe1416;
}

nav#nav-principal{
  .navigation_link_p{
    color: #fbcda9;
  }
  .navigation_button_p{
    color:#fbcda9;
    &:hover, &:focus, &.actif{
      .navigation_link_p{
        color: #fff;
      }
    }
  }
  .sous_navigation_link_p{
    color: #fff;
  }
  .sous_navigation_button_p{
    color:#fff;
    &:hover, &:focus, &.actif{
      > .sous_navigation_link_p{
        color : #fff;
      }
    }
  }
}

.produits p {
  color: #fbcda9;
}

.produits .nomprod {
  color: #fff;
}

.galerie{
  .mask{
    background:#000;
  }
  p{
    color:#fff;
  }
}

.galerie-photo p:hover, .galerie p.photo:hover {
  border: 1px solid #fe1416;
}

.galerie-photo p, .galerie p.photo {
  border: 1px solid #fbcda9;
}

.addcommentaires, .links_p a {
  color: #fe1416;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  background-color: #fe1416;
  border-color: #fe1416;
}

.pagination > li > a, .pagination > li > span {
  color: #fe1416;
}

.pagination.pull-right li.active a {
  color: #fff !important;
}

.row.two-column-blog .apercu-article h2.titre-article, .single-article .titre-article h2, .apercu-article .bloc-article .description-courte-article a.button.button-voir-plus, .apercu-article h2.titre-article-no-image, .single-article .titre-article-no-image h2,
.row.two-column-blog .apercu-article .h2.titre-article, .single-article .titre-article .h2, .apercu-article .h2.titre-article-no-image, .single-article .titre-article-no-image .h2 {
  text-transform: none;
}

.produits .produit_etiquette:after {
  border-color: rgb(134, 71, 22) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);

}

.produits .produit_etiquette {
  background: rgb(134, 71, 22);
}

.produits .produit_etiquette2 {
  background: rgb(134, 71, 22);
}

.produits .produit_etiquette2:after {
  border-color: rgba(0, 0, 0, 0) rgb(79, 49, 27) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

@media screen and (max-width: 778px) {
  .container_12 {
    background: #c46820 !important
  }
}

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: #c46820;
  border-color: #c46820;

  a {
    color: #fff;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: #c46820;
  color: #c46820;

  a, span {
    color: #c46820;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
  color: #000;
}

.template-panier{
  .count-live{
    background:#fff;
    color:#000;
  }
}